import { Link } from 'react-router-dom';
import { Ban, ArrowLeft, Home } from 'lucide-react'

import { Button } from "../components/ui/button"

export function NotFoundComponent() {
  return (
    (<div
      className="flex flex-col items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-900 px-4">
      <div className="text-center space-y-6 max-w-md">
        <Ban className="w-20 h-20 mx-auto text-red-500" aria-hidden="true" />
        <h1
          className="text-4xl font-bold tracking-tight text-gray-900 dark:text-gray-100 sm:text-5xl whitespace-nowrap">
          404 - Page Not Found
        </h1>
        <p className="text-xl text-gray-600 dark:text-gray-400">
          Oops! The page you're looking for doesn't exist or has been moved.
        </p>
        <div className="flex flex-col sm:flex-row gap-4 justify-center">
          <Button asChild size="lg">
            <Link to="/">
              <Home className="mr-2 h-4 w-4" />
              Go to Homepage
            </Link>
          </Button>
          <Button asChild variant="outline" size="lg">
            <Link to="..">
              <ArrowLeft className="mr-2 h-4 w-4" />
              Go Back
            </Link>
          </Button>
        </div>
      </div>
    </div>)
  );
}