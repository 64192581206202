import { ENDPOINTS } from '../../constants/apiEndpoints';
import axios from 'axios';
import { create } from 'zustand';
import { persist } from 'zustand/middleware'; // Add persist middleware if you want to persist auth state

const BASE_URL = ENDPOINTS.API_BASE_URL;

// Enhanced auth store with persistence and additional states
export const useAuthStore = create(
  persist(
    (set, get) => ({
      accessToken: null,
      isLoading: false,
      isAuthenticated: false,
      vcrs: null,
      user: null,
      error: null,

      setAccessToken: (token) => 
        set({ 
          accessToken: token, 
          isLoading: false,
          isAuthenticated: !!token,
          error: null
        }),

      setUser: (userData) =>
        set({ user: userData }),

      setVcrs: (vcrs_name) =>
        set({ vcrs: vcrs_name }),

      clearToken: () => 
        set({ 
          accessToken: null, 
          isLoading: false, 
          isAuthenticated: false,
          user: null,
          error: null
        }),

      setLoading: (loading) => 
        set({ isLoading: loading }),

      setError: (error) =>
        set({ error, isLoading: false }),

    }),
    {
      name: 'auth-storage', // Name for localStorage key
      partialize: (state) => ({ 
        accessToken: state.accessToken,
        user: state.user,
        vcrs: state.vcrs
      })
    }
  )
);

// Create axios instance with default config
export const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true, // Enable sending cookies
  timeout: 100000, // 100 second timeout
});

// Enhanced refresh token function with better error handling
const refreshAccessToken = async () => {
  try {
    useAuthStore.getState().setLoading(true);
    
    const response = await axios.get(ENDPOINTS.REFRESH, {
      baseURL: BASE_URL,
      withCredentials: true,
      // Add retry configuration for refresh attempts
      retry: 3,
      retryDelay: (retryCount) => retryCount * 1000, // Progressive delay
    });

    const newAccessToken = response.data.accessToken;
    useAuthStore.getState().setAccessToken(newAccessToken);

    return newAccessToken;
  } catch (error) {
    useAuthStore.getState().setError(error);
    useAuthStore.getState().clearToken();
    throw error;
  } finally {
    useAuthStore.getState().setLoading(false);
  }
};

// Request interceptor with error handling
api.interceptors.request.use(
  (config) => {
    const token = useAuthStore.getState().accessToken;
    
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    // Add request timestamp for timeout handling
    config.metadata = { startTime: new Date() };
    
    return config;
  },
  (error) => {
    useAuthStore.getState().setError(error);
    return Promise.reject(error);
  }
);

// Enhanced response interceptor with comprehensive error handling
api.interceptors.response.use(
  (response) => {
    // Clear any existing errors on successful response
    useAuthStore.getState().setError(null);
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // Handle request timeout
    if (error.code === 'ECONNABORTED') {
      useAuthStore.getState().setError(new Error('Request timed out'));
      return Promise.reject(error);
    }

    // Handle network errors
    if (!error.response) {
      useAuthStore.getState().setError(new Error('Network error'));
      return Promise.reject(error);
    }

    const { status } = error.response;

    // Handle token expiration and refresh
    if (status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const newAccessToken = await refreshAccessToken();
        
        // Update all active requests with new token
        api.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        
        return api(originalRequest);
      } catch (refreshError) {
        // Handle refresh token failure
        useAuthStore.getState().logout();
        return Promise.reject(refreshError);
      }
    }

    // Handle other common error cases
    switch (status) {
      case 403:
        useAuthStore.getState().setError(new Error('Access forbidden'));
        break;
      case 404:
        useAuthStore.getState().setError(new Error('Resource not found'));
        break;
      case 500:
        useAuthStore.getState().setError(new Error('Server error'));
        break;
      default:
        useAuthStore.getState().setError(error);
    }

    return Promise.reject(error);
  }
);

// Add response timeout handler
api.interceptors.response.use(
  (response) => {
    const duration = new Date() - response.config.metadata.startTime;
    console.debug(`Request to ${response.config.url} took ${duration}ms`);
    return response;
  }
);

// Export a helper to reset the api instance (useful for testing)
export const resetApiInstance = () => {
  api.defaults.headers.common['Authorization'] = '';
};