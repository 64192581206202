import { ENDPOINTS } from '../constants/apiEndpoints'; // Import the constants
import { useMutation, useQuery } from '@tanstack/react-query';
import { toast } from 'sonner';
import { api, useAuthStore } from '../lib/auth/auth-interceptor';
import axios from 'axios';

export const apiService = {
  // Handles the OAuth callback, sets the access token if present
  handleOAuthCallback: async (searchParams) => {
    // useAuthStore.getState().setLoading(true);
    try {
      const user_id = searchParams.get('user_id')
      const vcrs = searchParams.get('vcrs')
      if (user_id) {
        useAuthStore.getState().setUser(user_id);
        useAuthStore.getState().setVcrs(vcrs);
        return { user_id };
      }
    } catch (error) {
      // Handle the error, e.g., show an error message to the user
      toast.error('Error occurred during OAuth callback. Please try again.');
    } finally {
      useAuthStore.getState().setLoading(false);
    }
  },

  // Initiates login by redirecting to the OAuth provider's login URL
  handleLogin: async (provider) => {
    useAuthStore.getState().setLoading(true);


    const loginUrl = `${ENDPOINTS.LOGIN}/${provider}`;
    
    try {
      // Send a HEAD request to check if the login URL is accessible
      const response = await axios.get(ENDPOINTS.BASE, { timeout: 4000 });

      if (response.status === 200) {
        // If the response is successful (status 200-299), redirect the user
        window.location.href = loginUrl;
      } else {
        // If the response indicates an error, show an error message
        toast.error('There was an issue with the login URL. Please try again later.');
        useAuthStore.getState().setLoading(false);
      }
    } catch (error) {
      useAuthStore.getState().setLoading(false);
      // Handle network errors or other issues
      toast.error('There was an issue with the login process. Please try again later.');
    }
    // No need to stop the loader only when error accors because the login flow completes only when the callback ends.
    // useAuthStore.getState().setLoading(false);
  },

  getUser: async (provider) => {
    const state = useAuthStore.getState(); // Access the store state directly
    state.setLoading(true);

    const user = state.user;

    try {
      const { data } = await api.get(`${ENDPOINTS.GET_USER}/${user}`); // Using the constant
      return data;
    }catch(error){
      toast.error('Failed to fetch the user. Please try again.');
    }finally {
      state.setLoading(false);
    }
  },

  handleLogout: async () => {
    useAuthStore.getState().setLoading(true);
    try {
      // If there is a backend endpoint for logout
      await api.get(ENDPOINTS.LOGOUT);

      // Clear the access token and other auth-related data
      useAuthStore.getState().clearToken();

      toast.success('Logged out successfully!');
    } catch (error) {
      toast.error('Failed to log out. Please try again.');
    } finally {
      useAuthStore.getState().setLoading(false);
    }
  },

  // Fetches all repositories
  getAllRepositories: async () => {
    const state = useAuthStore.getState(); // Access the store state directly
    state.setLoading(true);

    const vcrs = state.vcrs;
    const user = state.user;

    try {
      const { data } = await api.get(`${ENDPOINTS.GET_REPOSITORIES}/${vcrs}/${user}`); // Using the constant
      return data;
    } finally {
      state.setLoading(false);
    }
  },

  // Toggles the vulnerability check for a specific repository
  toggleVulnerabilityCheck: async (repoId) => {
    const state = useAuthStore.getState(); // Access the store state directly
    const user = state.user;
    useAuthStore.getState().setLoading(true);
    try {
      const { data } = await api.post(`${ENDPOINTS.TOGGLE_VULNERABILITY_CHECK}/${repoId}/${user}`);
      return data;
    } catch (error) {
      throw error;
    } finally {
      useAuthStore.getState().setLoading(false);
    }
  },

  // Marks a specific repository
  markRepository: async (repoId) => {
    const state = useAuthStore.getState(); // Access the store state directly

    useAuthStore.getState().setLoading(true);
    const vcrs = state.vcrs;
    const user = state.user;
    try {
      const { data } = await api.post(`${ENDPOINTS.MARK_REPOSITORY}/${vcrs}/${user}/${repoId}`);
      return data; // Return the API response
    } catch (error) {
      throw error; // Re-throw the error for the caller to handle
    } finally {
      useAuthStore.getState().setLoading(false);
    }
  },

  // Unmarks a specific repository
  unMarkRepository: async (repoId) => {
    const state = useAuthStore.getState(); // Access the store state directly

    useAuthStore.getState().setLoading(true);
    const vcrs = state.vcrs;
    const user = state.user;
    try {
      const { data } = await api.delete(`${ENDPOINTS.UNMARK_REPOSITORY}/${vcrs}/${user}/${repoId}`);
      return data; // Return the API response
    } catch (error) {
      throw error; // Re-throw the error for the caller to handle
    } finally {
      useAuthStore.getState().setLoading(false);
    }
  },


  // Selects a model for a specific repository
  updateModel: async ( model ) => {
    const state = useAuthStore.getState(); // Access the store state directly
    useAuthStore.getState().setLoading(true);
    const user = state.user;
    console.log(model)
    try {
      const { data } = await api.post(`${ENDPOINTS.UPDATE_MODEL}/${user}`, {"model": model}); // Using the constant
      return data;
    } catch (error) {
      throw error;
    } finally {
      useAuthStore.getState().setLoading(false);
    }
  },

  // Selects a model for a specific repository
  getModel: async () => {
    useAuthStore.getState().setLoading(true);
    try {
      const { data } = await api.get(`${ENDPOINTS.GET_MODEL}`); // Using the constant
      return data;
    } catch (error) {
      throw error;
    } finally {
      useAuthStore.getState().setLoading(false);
    }
  },

};

// React Query hook for OAuth callback handling
export const useOAuthCallback = () => {
  return apiService.handleOAuthCallback;
};

export const useGetUser = () => {
  const user = useAuthStore((state) => state.user);
  return useQuery({
    queryKey: ['user', user],
    queryFn: apiService.getUser,
    enabled: !!user,  // Only run the query if the token is available
  });
};

// React Query hook for toggling the vulnerability check
export const useToggleVulnerabilityCheck = () => {
  return useMutation({
    mutationFn: apiService.toggleVulnerabilityCheck,
  });
};

// React Query hook for fetching all repositories
export const useRepositories = () => {
  const user = useAuthStore((state) => state.user);
  const vcrs = useAuthStore((state) => state.vcrs)

  return useQuery({
    queryKey: ['repositories', user, vcrs],
    queryFn: apiService.getAllRepositories,
    enabled: !!user,  // Only run the query if the token is available
  });
};

// React Query hook for marking a repository
export const useMarkRepository = () => {
  return useMutation({
    mutationFn: apiService.markRepository,
  });
};

// React Query hook for unmarking a repository
export const useUnMarkRepository = () => {
  return useMutation({
    mutationFn: apiService.unMarkRepository,
  });
};

// React Query hook for selecting a model for a repository
export const useUpdateModel = () => {
  return useMutation({
    mutationFn: (model) => apiService.updateModel(model),
  });
};

// React Query hook for selecting a model for a repository
export const useGetModels = () => {
  return useQuery({
    queryKey: ['models'],
    queryFn: apiService.getModel,
  });
};

// React Query hook for handling login
export const useHandleLogin = () => {
  return useMutation({
    mutationFn: (provider) => {
      apiService.handleLogin(provider);
    }
  });
};

// React Query hook for handling logout
export const useLogout = () => {
  return apiService.handleLogout
};
