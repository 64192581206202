import React, { createContext, useContext, useEffect } from 'react';
import { useAuthStore } from './auth-interceptor';
import { useNavigate } from 'react-router-dom';
import { useLogout } from '../../services/api-service';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const accessToken = useAuthStore((state) => state.accessToken);
  const isLoading = useAuthStore((state) => state.isLoading);
  const navigate = useNavigate();
  const handleLogout = useLogout();

  // Automatically redirect users based on authentication state
  useEffect(() => {
    if (!isLoading && !accessToken) {
      // No automatic redirect needed for WelcomePage
    }
  }, [accessToken, isLoading, navigate]);

  const login = (token) => {
    useAuthStore.getState().setAccessToken(token);
  };

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        isLoading,
        login,
        handleLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the AuthContext
export const useAuth = () => {
  return useContext(AuthContext);
};
