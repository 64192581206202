import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ProtectedRoute, PublicRoute } from './lib/auth/auth-guard';
import { AuthProvider } from './lib/auth/auth-provider';
import { OauthLogin }  from './components/oauth-login';
import { WelcomePageComponent as WelcomePage } from './components/welcome-page';
import { DashboardComponent as Dashboard } from './components/dashboard';
import { NotFoundComponent } from './components/not-found';
import { Toaster } from "./components/ui/sonner";
import OAuthCallback from './components/oauth-callback';

function App() {
  return (
    <AuthProvider>
      <Toaster position="bottom-right" richColors />
      <Routes>
        {/* Public routes */}
        <Route
          path="/"
          element={
              <WelcomePage />
          }
        />
        <Route
          path="/login"
          element={
            <PublicRoute>
              <OauthLogin />
            </PublicRoute>
          }
        />
        <Route
          path="/callback"
          element={
            <PublicRoute>
              <OAuthCallback />
            </PublicRoute>
          }
        />

        {/* Protected routes */}
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />

        {/* Fallback route for undefined paths */}
        <Route path="*" element={<NotFoundComponent />} />
      </Routes>
    </AuthProvider>
  );
}

export default App;
