// src/constants/apiEndpoints.js

// Use environment variable for API base URL, with a fallback to localhost
export const API_BASE_URL = process.env.REACT_APP_API_URL;

export const ENDPOINTS = {
  BASE: API_BASE_URL,
  LOGIN: `${API_BASE_URL}/oauth/login`,
  LOGOUT: `${API_BASE_URL}/oauth/logout`,
  REFRESH: `${API_BASE_URL}/api/auth/refresh`,
  GET_USER: `${API_BASE_URL}/oauth/users`,
  GET_REPOSITORIES: `${API_BASE_URL}/oauth/api/repositories`,
  MARK_REPOSITORY: `${API_BASE_URL}/oauth/api/set-webhook`,
  UNMARK_REPOSITORY: `${API_BASE_URL}/oauth/api/delete-webhook`,
  TOGGLE_VULNERABILITY_CHECK: `${API_BASE_URL}/oauth/api/vulnerability_test`,
  GET_MODEL: `${API_BASE_URL}/oauth/api/get-models`,
  UPDATE_MODEL: `${API_BASE_URL}/oauth/api/set-model`,
};
