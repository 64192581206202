import { useState, useEffect } from 'react'
import { ChevronDown, Github, Search, Brain, Star, Loader2, EyeIcon as EyeClosed, ShieldCheck, GitFork, Eye } from 'lucide-react'

import { Avatar, AvatarFallback, AvatarImage } from '../components/ui/avatar'
import { Button } from '../components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/card'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../components/ui/dropdown-menu'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../components/ui/tooltip"

import { Input } from '../components/ui/input'
import { ScrollArea } from '../components/ui/scroll-area'
import { SelectLLMModel } from './select-llm-model'
import { Switch } from '../components/ui/switch'
import { Badge } from '../components/ui/badge'

import { useAuth } from '../lib/auth/auth-provider'
import { toast } from 'sonner'
import { useRepositories, useMarkRepository, useUnMarkRepository, useGetUser, useToggleVulnerabilityCheck } from '../services/api-service'

export function DashboardComponent() {
  const [searchQuery, setSearchQuery] = useState('')
  const [currentPage, setCurrentPage] = useState('home')
  const [loadingRepo, setLoadingRepo] = useState(null)
  const [vulnerabilityState, setVulnerabilityState] = useState({}); // Tracks switch states by repo ID
  const [loadingVulnerabilityCheck, setLoadingVulnerabilityCheck] = useState(null)
  const { handleLogout } = useAuth()

  const handleLogouts = () => {
    handleLogout()
  }

  const { data: user, isUserLoading } = useGetUser()
  const { data: repositories, isLoading, isError, refetch } = useRepositories()
  const { mutateAsync: markRepository } = useMarkRepository()
  const { mutateAsync: unMarkRepository } = useUnMarkRepository()
  const { mutateAsync: toggleVulnerabilityCheck } = useToggleVulnerabilityCheck()

  const filteredRepos = repositories
    ? repositories.filter((repo) =>
        repo.name.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : []

    useEffect(() => {
      if (repositories) {
        const initialState = repositories.reduce((state, repo) => {
          state[repo.id] = repo.is_vulnerability_enabled === "true";
          return state;
        }, {});
        setVulnerabilityState(initialState);
      }
    }, [repositories]);

  const toggleRepoSelection = async (repo) => {
    setLoadingRepo(repo.id);
    const newState = repo.acrs === "true" ? "false" : "true";
  
    try {
      const mutation = repo.acrs === "true" ? unMarkRepository : markRepository;
      await mutation(repo.id)
      repo.acrs = newState;
      
      toast.success(
        `Automatic code review is now ${
          newState === "true" ? "enabled" : "disabled"
        } for ${repo.name}.`
      );
    } catch (error) {        
      if (error.isAxiosError) {
        if (error.response) {
          toast.error(
            `Error ${error.response.status}: ${
              error.response.data?.message || 
              `Failed to change the status for ${repo.name}`
            }`
          );
        } else if (error.request) {
          toast.error('No response received from server. Please try again.');
        } else {
          toast.error(`Error: ${error.message}`);
        }
      } else {
        toast.error(`Unexpected error occurred. Please try again.`);
      }
    } finally {
      setLoadingRepo(null);
    }
  };

  const handleToggleVulnerabilityCheck = async (repo) => {
    const currentState = vulnerabilityState[repo.id];
    setLoadingVulnerabilityCheck(repo.id);
    
    try {
      await toggleVulnerabilityCheck(repo.id);
      // Add 1 second delay
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      // Update the state with the new value
      setVulnerabilityState((prevState) => ({
        ...prevState,
        [repo.id]: !currentState,
      }));
      
      toast.success(
        `Vulnerability check is now ${
          !currentState ? "enabled" : "disabled"
        } for ${repo.name}.`
      );
    } catch (err) {
      toast.error(`Failed to toggle vulnerability check for ${repo.name}.`);
    } finally {
      setLoadingVulnerabilityCheck(null);
    }
  };
  

  const renderRepositories = () => {
    if (isLoading) {
      return (
        <div className="flex flex-col items-center justify-center h-full">
          <Loader2 className="h-12 w-12 animate-spin text-primary mb-4" />
          <p className="text-lg font-semibold text-gray-700 dark:text-gray-300">Loading repositories...</p>
        </div>
      )
    }

    if (isError) {
      return (
        <div className="flex flex-col items-center justify-center h-full text-center p-4">
          <EyeClosed className="h-16 w-16 text-muted-foreground mb-4" aria-hidden="true" />
          <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100">Error loading repositories</h3>
          <p className="text-sm text-muted-foreground mt-2">Failed to load repositories. Please try again later.</p>
          <Button className="mt-4" onClick={() => refetch()}>Try again</Button>
        </div>
      )
    }

    if (filteredRepos.length === 0) {
      return (
        <div className="flex flex-col items-center justify-center h-full text-center p-4">
          <EyeClosed className="h-16 w-16 text-muted-foreground mb-4" aria-hidden="true" />
          <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100">No repositories found</h3>
          <p className="text-sm text-muted-foreground mt-2">
            {searchQuery
              ? "We couldn't find any repositories matching your search."
              : "It looks like you don't have any repositories yet."}
          </p>
          {!searchQuery && (
            <Button className="mt-4" asChild>
              <a href="https://github.com/new" target="_blank" rel="noopener noreferrer">Create a new repository</a>
            </Button>
          )}
        </div>
      )
    }

    return (
      <div className="space-y-4">
        {filteredRepos.map((repo) => (
          <Card key={repo.id} className="hover:shadow-md transition-shadow duration-200">
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <div className="flex items-center space-x-2">
                <CardTitle className="text-lg font-semibold">{repo.name}</CardTitle>
                <Badge 
                  variant={repo.visibility === "private" ? "destructive" : "success"} 
                  className={repo.visibility === "private" ? "bg-red-100 text-red-800 hover:bg-red-200" : "bg-green-100 text-green-800 hover:bg-green-200"}
                >
                  {repo.visibility === "private" ? 'Private' : 'Public'}
                </Badge>
              </div>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <div className="flex items-center">
                      {loadingRepo === repo.id ? (
                        <Loader2 className="h-5 w-5 animate-spin" />
                      ) : (
                        <Switch
                          id={`repo-${repo.id}`}
                          checked={repo.acrs === "true"}
                          onCheckedChange={() => toggleRepoSelection(repo)}
                        />
                      )}
                    </div>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>{repo.acrs === "true" ? 'Disable' : 'Enable'} Automated Code Review</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </CardHeader>
            <CardContent>
              <p className="text-sm text-muted-foreground mb-4">{repo.description}</p>
              {repo.acrs === "true" && (
                <div className="mb-4 pl-4 flex items-center justify-between bg-gray-100 dark:bg-gray-800 p-3 rounded-md w-fit">
                  <div className="flex items-center space-x-2 mr-4">
                    <ShieldCheck className="h-5 w-5 text-purple-500" />
                    <span className="font-medium text-sm whitespace-nowrap">Vulnerability Check</span>
                  </div>
                  {loadingVulnerabilityCheck === repo.id ? (
                    <Loader2 className="h-5 w-5 animate-spin" />
                  ) : (
                    <Switch
                    checked={vulnerabilityState[repo.id] ?? false} // Fallback to false if not initialized
                    onCheckedChange={() => handleToggleVulnerabilityCheck(repo)}
                    />
                  )}
                </div>
              )}
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-4 text-sm text-muted-foreground">
                  <div className="flex items-center">
                    <Star className="mr-1 h-4 w-4" />
                    <span>{repo.stars}</span>
                  </div>
                  <div className="flex items-center">
                    <GitFork className="mr-1 h-4 w-4" />
                    <span>{repo.forks}</span>
                  </div>
                  <div className="flex items-center">
                    <Eye className="mr-1 h-4 w-4" />
                    <span>{repo.watchers}</span>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>
    )
  }

  const renderPage = () => {
    switch (currentPage) {
      case 'select-llm':
        return <SelectLLMModel user={user} isUserLoading={isUserLoading} />
      default:
        return (
          <div className="p-6">
            <div className="mb-6 flex items-center space-x-4">
              <div className="relative flex-1">
                <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
                <Input
                  placeholder="Search repositories"
                  className="pl-8"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="outline">
                    Actions
                    <ChevronDown className="ml-2 h-4 w-4" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuItem>Archive selected</DropdownMenuItem>
                  <DropdownMenuItem>Delete selected</DropdownMenuItem>
                  <DropdownMenuItem>Change visibility</DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
            <ScrollArea className="h-[calc(100vh-12rem)] rounded-md border">
              {renderRepositories()}
            </ScrollArea>
          </div>
        )
    }
  }

  return (
    <div className="flex h-screen bg-gray-100 dark:bg-gray-900">
      <aside className="w-64 bg-white dark:bg-gray-800 border-r border-gray-200 dark:border-gray-700">
        <div className="p-4">
          <h1 className="text-2xl font-bold text-gray-800 dark:text-white mb-4">Dashboard</h1>
          <nav className="space-y-2">
            <Button
              variant="ghost"
              className="w-full justify-start"
              onClick={() => setCurrentPage('repositories')}>
              <Github className="mr-2 h-4 w-4" />
              Repositories
            </Button>
            <Button
              variant="ghost"
              className="w-full justify-start"
              onClick={() => setCurrentPage('select-llm')}>
              <Brain className="mr-2 h-4 w-4" />
              AI Models
            </Button>
          </nav>
        </div>
      </aside>
      <main className="flex-1 overflow-hidden">
        <header className="bg-white dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700 p-4">
          <div className="flex items-center justify-between">
            <h2 className="text-xl font-semibold text-gray-800 dark:text-white">
              {currentPage === 'select-llm' ? 'Select LLM Model' : 'My Repositories'}
            </h2>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="relative h-8 w-8 rounded-full">
                  <Avatar className="h-8 w-8">
                    <AvatarImage src="/placeholder-avatar.jpg" alt="@johndoe" />
                    <AvatarFallback>
                      {user?.username
                        .split(' ')
                        .map((namePart) => namePart[0]?.toUpperCase())
                        .join('')}
                    </AvatarFallback>
                  </Avatar>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-56" align="end" forceMount>
                <DropdownMenuLabel className="font-normal">
                  <div className="flex flex-col space-y-1">
                    <p className="text-sm font-medium leading-none">{user?.username}</p>
                    <p className="text-xs leading-none text-muted-foreground">{user?.user_email}</p>
                  </div>
                </DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuItem onClick={handleLogouts}>
                  Log out
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </header>
        {renderPage()}
      </main>
    </div>
  )
}