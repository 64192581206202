import { Link } from 'react-router-dom';
import { GitPullRequest } from 'lucide-react';
import { Button } from "../components/ui/button";
import { useAuth } from '../lib/auth/auth-provider'; // Assuming you have this hook to get auth state

export function WelcomePageComponent() {
  const { accessToken } = useAuth(); // Access token from auth context or store

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-900 p-4">
      <div className="text-center space-y-6 max-w-2xl">
        <GitPullRequest className="w-16 h-16 mx-auto text-primary" />
        <h1 className="text-4xl font-bold tracking-tight text-gray-900 dark:text-gray-100 sm:text-5xl md:text-6xl">
          Welcome to <br /> Automatic Code Review System
        </h1>
        <p className="text-xl text-gray-600 dark:text-gray-400">
            Streamline your repository management and harness the power of AI to boost your productivity.        </p>
        <div className="flex flex-col sm:flex-row gap-4 justify-center">
          {accessToken ? (
            // If user is authenticated, show the "Account" button
            <Button asChild size="lg">
              <Link to="/dashboard">Go to Account</Link>
            </Button>
          ) : (
            // If user is not authenticated, show the "Login" button
            <Button asChild size="lg">
              <Link to="/login">Login</Link>
            </Button>
          )}
          <Button asChild variant="outline" size="lg">
            <Link>Learn More</Link>
          </Button>
        </div>
      </div>
      <footer className="mt-16 text-center text-sm text-gray-500 dark:text-gray-400">
        © 2024 ACRS. All rights reserved.
      </footer>
    </div>
  );
}
