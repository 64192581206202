import { useState, useEffect, useCallback } from 'react';
import { Brain, Loader2 } from 'lucide-react';
import { toast } from 'sonner';

import { Button } from '../components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '../components/ui/card';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../components/ui/select';

import { useUpdateModel, useGetModels } from '../services/api-service'

export function SelectLLMModel({ user, isUserLoading }) {  
  const [isLoading, setIsLoading] = useState(false)
  const { mutateAsync: updateModel } = useUpdateModel();
  const { data: models = [] } = useGetModels();

  // Find the matching model display value for the current user model
  const getCurrentModelDisplay = useCallback((userModel) => {
    return models.find(m => m.value === userModel?.toLowerCase())?.display;
  }, [models]);

  const [selectedModel, setSelectedModel] = useState(getCurrentModelDisplay(user?.model_preference))

  useEffect(() => {
    if (user?.model_preference) {
      setSelectedModel(getCurrentModelDisplay(user.model_preference));
    }
  }, [user, getCurrentModelDisplay]);

  const handleApplySelection = async () => {
    if (!selectedModel) {
      toast.error('Please select a model before applying.');
      return;
    }

    if (selectedModel === 'GPT-4' && !user?.is_admin) {
      toast.error('GPT-4 is only available to administrators.');
      return;
    }
  
    setIsLoading(true);
  
    try {
      // Get the lowercase value for API call
      const modelValue = models.find((m) => m.display === selectedModel)?.value;
  
      if (!modelValue) {
        throw new Error('Invalid model selection.');
      }
  
      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 1000)); // 1-second delay
      await updateModel(modelValue);
      toast.success(`Successfully applied model: ${selectedModel}`);
    } catch (error) {
      // Handle specific error cases
      if (error.response) {
        // Errors from API response (e.g., 4xx or 5xx status codes)
        toast.error(`Failed to apply model: ${error.response.data.message || error.message}`);
      } else if (error.request) {
        // Errors due to no response from server
        toast.error('Network error: No response from server.');
      } else {
        // Other errors (e.g., coding issues or thrown errors)
        toast.error(`An unexpected error occurred: ${error.message}`);
      }
    } finally {
      setIsLoading(false); // Ensure loader stops regardless of success or failure
    }
  };
  

  if (isUserLoading) {
    return (
      <div className="container mx-auto px-4 py-8 flex items-center justify-center">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <Card className="max-w-2xl mx-auto">
        <CardHeader>
          <div className="flex items-center space-x-2">
            <Brain className="w-6 h-6 text-primary" />
            <CardTitle className="text-2xl font-bold">Select LLM Model</CardTitle>
          </div>
          <CardDescription>
            Choose the Large Language Model (LLM) that best fits your needs. Your selection will
            determine the AI's capabilities and performance.
          </CardDescription>
          {user?.current_model && (
            <p className="text-sm text-muted-foreground mt-2">
              Current Model: {getCurrentModelDisplay(user.current_model)}
            </p>
          )}
        </CardHeader>
        <CardContent className="space-y-6">
          <div className="space-y-2">
            <Select onValueChange={setSelectedModel} value={selectedModel}>
              <SelectTrigger id="model" className="w-full">
                <SelectValue placeholder="Select a model" />
              </SelectTrigger>
              <SelectContent>
              {models.map(({ display }) => {
                  // Check if the model is GPT-4 and user is not admin
                  const isGPT4Restricted = display === 'GPT-4' && !user?.is_admin;
                  
                  return (
                    <SelectItem 
                      key={display} 
                      value={display} 
                      disabled={isGPT4Restricted}
                      className={isGPT4Restricted ? 'opacity-50 cursor-not-allowed' : ''}
                    >
                      {display}
                      {isGPT4Restricted && ' (Admin only)'}
                    </SelectItem>
                  );
                })}
              </SelectContent>
            </Select>
          </div>

          <Button
            className="w-full"
            onClick={handleApplySelection}
            disabled={getCurrentModelDisplay(user?.model_preference) === selectedModel || isLoading}>
            {isLoading ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Applying...
              </>
            ) : (
              'Apply Selection'
            )}
          </Button>
        </CardContent>
      </Card>
    </div>
  );
}