import { useState } from 'react'
import { Github, GitlabIcon as GitlabLogo, Building2, GitPullRequest } from 'lucide-react'
import { Button } from "../components/ui/button"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../components/ui/card"
import { useHandleLogin } from '../services/api-service'

export function OauthLogin() {
  const [isLoading, setIsLoading] = useState(null);

  const { mutate: handleLogin } = useHandleLogin();  // Access 'mutate' as handleLogin

  const handleChange = (provider) => {
    // Set the loading state before redirecting
    setIsLoading(provider);
  
    // Redirect to the login URL
    handleLogin(provider);
  
    // Reset the loading state after a brief timeout (to simulate the loading state)
    setTimeout(() => setIsLoading(null), 2000);
  };

  return (
    (<div
      className="flex items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-900">
      <Card className="w-full max-w-md">
        <CardHeader className="space-y-1">
        <GitPullRequest className="w-8 h-8 mx-auto text-primary" />
          <CardTitle className="text-2xl font-bold text-center">Login to ACRS</CardTitle>
          <CardDescription className="text-center">
          Please select your preferred method to sign in.
          </CardDescription>
        </CardHeader>
        <CardContent className="grid gap-4">
          <Button
            variant="outline"
            className="w-full"
            onClick={() => handleChange('github')}
            disabled={isLoading !== null}>
            <Github className="mr-2 h-4 w-4" />
            {isLoading === 'github' ? 'Logging in...' : 'Login with GitHub'}
          </Button>
          <Button
            variant="outline"
            className="w-full"
            onClick={() => handleChange('gitlab')}
            disabled={isLoading !== null}>
            <GitlabLogo className="mr-2 h-4 w-4" />
            {isLoading === 'githab' ? 'Logging in...' : 'Login with GitLab'}
          </Button>
          <Button
            variant="outline"
            className="w-full"
            onClick={() => handleChange('qburst')}
            disabled={isLoading !== null}>
            <Building2 className="mr-2 h-4 w-4" />
            {isLoading === 'qburst' ? 'Logging in...' : 'Login with Code.Qburst'}
          </Button>
        </CardContent>
      </Card>
    </div>)
  );
}