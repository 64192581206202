import React from 'react'
import { Loader2 } from 'lucide-react'

export default function LoadingPage() {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-900">
      <div className="text-center">
        <Loader2 className="h-16 w-16 animate-spin text-primary mx-auto" />
        <h1 className="mt-4 text-2xl font-semibold text-gray-900 dark:text-gray-100">Loading...</h1>
        <p className="mt-2 text-gray-600 dark:text-gray-400">Please wait while we set things up for you.</p>
      </div>
    </div>
  )
}